<template>
    <div class='videoList'>
        <!-- is_draft=2为作品 is_draft=1为草稿 -->
        <div class="list">
            <div class="item">
                <div class="add-card" @click="toCreate">
                    <div class="add-handle">
                        <img src="@/assets/images/creation/creationMng_(31).png" alt="">
                        <div class="new-blank"> 新建空白 </div>
                    </div>
                </div>
            </div>
            <div class="item" v-for="(item, index) in nlist" :key="index">
                <div class="creation-card">
                    <div class="video-container" v-if="item.status==5">
                        <div v-show="!item.icon_play" class="icon_play" @click="playBtn(index)"></div>
                        <img v-show="!item.icon_play" :src="item.video_img" alt="" class="creation-cover-img">
                        <!-- <video v-if="!item.icon_play" autoplay controls="controls" class="creation-cover" style=""
                            :poster="item.video_img" :src="item.video_url"></video> -->
                        <video v-show="item.icon_play" class="creation-cover" autoplay :src="item.video_url"
                            :ref="'tempVideo' + index" :poster="item.video_img" controls="controls"
                            @ended="videoEndedBtn(item)" @pause="videoPauseBtn(item)"
                            controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>

                        <div class="left-top" @click="posterBtn(item)">封面</div>
                    </div>
                    <div class="video-container" v-else>
                        <div class="btnBgColor_blue edit_btn cursor" v-if="item.status == 1 || item.status == 4">去编辑 ></div>
                        <img :src="item.video_img" alt="" class="creation-cover-img">
                        <div class="right-top p-edit" v-if="item.status == 1">编辑中</div>
                        <div class="right-top p-progress" v-if="item.status == 3">合成中</div>
                        <div class="right-top p-paidui" v-if="item.status == 2">排队中</div>
                        <div class="right-top p-err" v-if="item.status == 4">创作失败</div>
                    </div>
                    <div class="card-info">
                        <div class="left">
                            <edit-name :name="item.name" :id="item.id" @toDetail="toDetail"
                                @editName="nameChange"></edit-name>
                            <div class="creation-time">创建时间 {{ item.create_time }}</div>
                        </div>
                        <div class="right">
                            <el-popover placement="bottom-end" width="145" trigger="hover" popper-class="more-btn"
                                :key="'pop' + index">
                                <div class="content">
                                    <div class="item">复制并编辑</div>
                                    <div class="item" @click="copyUrl(item.video_url)" v-if="item.status == 5">复制链接</div>
                                    <div class="item" @click="posterBtn(item)" v-if="item.status == 5">设置封面</div>
                                    <div class="item" @click="downloadFile(item.video_url, item.name)"
                                        v-if="item.status == 5">下载</div>
                                    <div class="item c_e" @click="delBtn(item.id)">删除</div>
                                </div>
                                <span slot="reference" class="el-icon-more more"></span>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提示弹框 -->
        <point-out ref='dialogTip' type='account' @close='accountDelBtn' dialogTitle="提示" dialogWidth='380px'
            :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">作品删除后不可恢复，请确认是否删除？</div>
            </div>
        </point-out>
        <poster-set ref="poster"></poster-set>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示弹框
import EditName from '../editCreationName/index.vue'
import PosterSet from '../posterSet/index.vue'
export default {
    components: { PointOut, EditName, PosterSet },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            id: null,
        }
    },
    computed: {
        nlist() {
            let list = this.list
            list.forEach(item => {
                this.$set(item, "icon_play", false)

            })
            return list
        }
    },
    watch: {},
    created() {

    },
    mounted() { },
    methods: {
        delBtn(id) {
            this.id = id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 删除
        accountDelBtn() {
            this.$creationApi.delVideo(this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$emit('refreshBtn')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 详情
        toDetail(val) {
            let path = '/creation/videoDetails/' + val.id
            // console.log(val.name)
            // return
            this.$router.push({
                path: path,
                query: {
                    name: val.name
                }
            })
        },
        // 昵称
        nameChange(val) {
            this.$creationApi.editVideoName({ name: val.name }, val.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$emit('refreshBtn')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 封面
        posterBtn(item) {
            this.$refs.poster.openDialogBtn(item)
        },
        toCreate() {

        },
        // 复制
        copyUrl(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
        // 下载
        downloadFile(url, name) {
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const a = document.createElement("a");
                    const objectUrl = window.URL.createObjectURL(blob);
                    a.download = name;
                    a.href = objectUrl;
                    a.click();
                    window.URL.revokeObjectURL(objectUrl);
                    a.remove();
                })
        },
        // 视频播放暂停
        videoPauseBtn(v) {
            this.$set(v, "icon_play", false)
        },
        // 视频播放结束
        videoEndedBtn(v) {
            this.$set(v, "icon_play", false)
        },
        // 播放
        playBtn(index) {
            this.nlist.forEach((v, i) => {
                if (index == i) {
                    this.$set(v, "icon_play", true)
                    let refPlay = eval('this.$refs.tempVideo' + index)[0]
                    refPlay.play()//播放
                } else if (v.status == 5) {
                    let refPause = eval('this.$refs.tempVideo' + i)[0]
                    refPause.pause()//暂停
                    this.$set(v, "icon_play", false)

                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.videoList {
    .list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-right: -30px;
        width: 100%;

        .item {
            margin-right: 25px;

        }

        .add-card {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            width: 314px;
            height: 178px;
            background: rgba(66, 94, 255, 0.08);
            border-radius: 10px;
            border: 1px dashed $blueColor1;
            margin-bottom: 20px;

            .add-handle {
                width: 314px;
                height: 178px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 16px;
                }

                .new-blank {
                    width: 98px;
                    height: 32px;
                    font-size: 14px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $blueColor1;
                }


            }
        }

        .creation-card {
            position: relative;
            // margin-right: 30px;
            margin-bottom: 20px;

            .video-container {
                width: 314px;
                height: 178px;
                background: linear-gradient(180deg, #c9cbd8, #bcbdc7);
                text-align: center;
                border-radius: 10px;
                overflow: hidden;
            }

            .creation-cover-img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            .creation-cover {
                width: 315px;
                height: 178px;
                border-radius: 10px 10px 10px 10px;
                -o-object-fit: contain;
                object-fit: contain;
            }

            .edit_btn {
                position: absolute;
                top: calc(178px / 2 - 32px / 2);
                left: calc(315px / 2 - 68px / 2);
                width: 68px;
                height: 32px;
                border-radius: 4px;
                line-height: 32px;
                text-align: center;
                font-size: 12px;
            }

            .left-top {
                width: 48px;
                line-height: 20px;
                background: $blueColor1;
                border-radius: 0px 10px 0px 10px;
                position: absolute;
                font-size: 12px;
                right: 0;
                top: 0;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
                display: none;
            }

            .source-cover {
                width: 48px;
                line-height: 20px;
                background: $blueColor1;
                border-radius: 0px 10px 0px 10px;
                position: absolute;
                font-size: 12px;
                right: 0;
                top: 0;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
                display: none;

            }

            .video-container:hover .source-cover {
                display: block;
            }

            .right-top {
                min-width: 48px;
                padding: 0 5px;
                line-height: 20px;
                border-radius: 10px 0px 10px 0px;
                position: absolute;
                font-size: 12px;
                left: 0;
                top: 0;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }

            .p-edit {
                background: linear-gradient(90deg, $blueColor1 0%, $blueColor2 100%);
                color: #fff;
            }

            .p-progress {
                background: #FF7432;
                color: #fff;
            }

            .p-paidui {
                background: #BB1AFD;
                color: #fff;
            }

            .p-progress-btn {
                color: #fff;
                border: 1px solid #fff;
            }

            .p-err {
                background: #E51D41;
                color: #fff;
            }

            .video-container:hover .left-top {
                display: block;

            }

            .icon_play {
                position: absolute;
                top: calc(178px / 2 - 56px / 2);
                left: calc(315px / 2 - 56px / 2);
                background-image: url('~@/assets/images/creation/creationMng_(35).png');
                background-repeat: no-repeat;
                background-size: 20px 24px;
                background-position: 50% 50%;
                width: 56px;
                height: 56px;
                cursor: pointer;
                background-color: #000;
                border-radius: 50px;
                display: none;

            }

            .video-container:hover .icon_play {
                display: block;
            }

            .video-container:hover .edit_btn {
                display: block;
            }

            .card-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {

                    .edit-name {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 28px;

                        .btn-edit {
                            margin-left: 10px;
                        }
                    }

                    .creation-time {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .right {
                    .more {
                        font-size: 14px;
                        color: #333333;
                        transform: rotate(90deg);
                        cursor: pointer;

                    }
                }
            }
        }

    }

}

.cursor {
    cursor: pointer;
}
</style>
<style lang="scss">
.more-btn {
    // background-color: rgba($color: #fff, $alpha: 0.5);

    .content {
        .item {
            font-size: 11px;
            font-weight: 400;
            color: #333333;
            padding: 5px 10px;
            cursor: pointer;
        }

        .c_e {
            color: #F25353;
        }

        .item:hover {
            background: $blueColor1;
            color: #fff;

        }
    }

}
</style>
