<template>
    <div class="preset">
        <div class="title">预设样式</div>
        <div class="style-list">
            <div class="style-item" :class="textStyle == item.value ? 'active' : ''" v-for="(item, index) in list"
                :key="index" @click="insertTextPreset(item.value)">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="flex-box">
            <div class="border-style">
                <img src="@/assets/images/creation/creationMng_(68).png" alt="">
                <div class="txt">粗细</div>
                <div class="slider-box">
                    <el-slider size="mini" v-model="borderWidth" :step="1" :min="0" :max="20"
                        :show-tooltip="false"></el-slider>
                </div>
            </div>
            <div class="border-style">
                <div class="txt">{{ borderWidth }}</div>
            </div>
            <el-color-picker class="ml10" v-model="borderColor" size="small"></el-color-picker>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [
                {
                    img: require('@/assets/images/creation/creationMng_(71).png'),
                    value: 0
                },
                {
                    img: require('@/assets/images/creation/creationMng_(61).png'),
                    value: 1
                },
                {
                    img: require('@/assets/images/creation/creationMng_(60).png'),
                    value: 2
                },
                {
                    img: require('@/assets/images/creation/creationMng_(59).png'),
                    value: 3
                },
                {
                    img: require('@/assets/images/creation/creationMng_(58).png'),
                    value: 4
                },
                {
                    img: require('@/assets/images/creation/creationMng_(57).png'),
                    value: 5
                },
                {
                    img: require('@/assets/images/creation/creationMng_(56).png'),
                    value: 6
                },
                {
                    img: require('@/assets/images/creation/creationMng_(55).png'),
                    value: 7
                },
                {
                    img: require('@/assets/images/creation/creationMng_(54).png'),
                    value: 8
                },
                {
                    img: require('@/assets/images/creation/creationMng_(53).png'),
                    value: 9
                },
                {
                    img: require('@/assets/images/creation/creationMng_(52).png'),
                    value: 10
                },
                {
                    img: require('@/assets/images/creation/creationMng_(51).png'),
                    value: 11
                },
                {
                    img: require('@/assets/images/creation/creationMng_(50).png'),
                    value: 12
                },
                {
                    img: require('@/assets/images/creation/creationMng_(62).png'),
                    value: 13
                },
            ],
            textStyle: '',
            borderWidth: 10,
            borderColor: '',
        }
    },
    methods: {
        insertTextPreset(val) {
            this.textStyle = val
        }
    }
}
</script>
<style lang="scss" scoped>
.preset {
    margin-top: 20px;

    .title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }

    .style-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-top: 16px;

        .style-item {
            width: 34px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            margin-right: 20px;
            margin-bottom: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .style-item.active {
            border-color: $blueColor1;
        }
    }

    .border-style {
        // width: 126px;
        min-width: 32px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #D1D1D1;
        display: flex;
        align-items: center;
        padding: 8px;
        margin-right: 4px;
        img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
        }

        .txt {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            // margin-left: 2px;
        }

        ::v-deep .slider-box {
            width: 50px;
            margin: 0 6px;

            .el-slider__runway {
                height: 2px;
                margin: 2px 0;
            }

            .el-slider__bar {
                height: 2px;
            }

            .el-slider__button-wrapper {
                height: 32px;
            }

            .el-slider__button {
                width: 6px;
                height: 6px;
                border: 1px solid $blueColor1;
            }
        }
    }

    .flex-box {
        display: flex;
    }

}
</style>