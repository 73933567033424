<template>
    <div class="right-box">
        <div class="content-box">
            <div class="content-hearder" v-if="tabActive != 3">
                <div class="content-hearder-item">
                    {{ tabName }}
                </div>
            </div>
            <div class="content-hearder" v-else>
                <div class="content-hearder-item" :class="contentHearder == item.value ? 'active' : ''"
                    v-for="item in contentHearderList" :key="item.value" @click="contentChange(item.value)">
                    {{ item.label }}
                    <div class="bar"></div>
                </div>
            </div>
            <div class="content-main">
                <div class="" v-if="tabActive == 1">
                    <div class="btnBgColor_blue upload-btn">
                        <div class="upload-btn-con">
                            <div>上传图片</div>
                            <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
                                <img src="@/assets/images/creation/creationMng_(27).png" alt="">
                            </el-tooltip>
                        </div>
                    </div>

                    <div class="imgs-list">
                        <div class="img-item">
                            <img class="img" src="@/assets/images/creation/creationMng_(1).png" alt="">
                            <div class="mask">
                                <img class="look" src="@/assets/images/creation/creationMng_(26).png" alt="">
                                <div class="btnBgColor_blue">插入</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-if="tabActive == 2">
                    <div class="btnBgColor_blue upload-btn">
                        <div class="upload-btn-con">
                            <div>添加文字</div>
                        </div>
                    </div>
                    <text-preset></text-preset>
                </div>
                <div v-if="tabActive == 3">
                    <div v-if="contentHearder == 1">
                        <text-style></text-style>
                    </div>
                    <div v-if="contentHearder == 2">
                        <layer-style></layer-style>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-box">
            <div class="tab-item" :class="tabActive == item.value ? 'active' : ''" v-for="(item, index) in tabList"
                :key="index" @click="tabChange(item)">
                <img class="icon" v-if="tabActive == item.value" :src="item.iconActive" alt="">
                <img class="icon" v-else :src="item.icon" alt="">
                <div class="txt" :class="tabActive == item.value ? 'active' : ''">{{ item.label }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import TextPreset from '../textStyle/preset.vue'
import TextStyle from '../textStyle/index.vue'
import LayerStyle from '../textStyle/layer.vue'
export default {
    components: {
        TextPreset,
        TextStyle,
        LayerStyle,
    },
    data() {
        return {
            tabList: [
                {
                    icon: require('@/assets/images/creation/creationMng_(18).png'),
                    iconActive: require('@/assets/images/creation/creationMng_(21).png'),
                    label: '我的',
                    value: 1,
                },
                {
                    icon: require('@/assets/images/creation/creationMng_(22).png'),
                    iconActive: require('@/assets/images/creation/creationMng_(17).png'),
                    label: '文本',
                    value: 2,
                },
                {
                    icon: require('@/assets/images/creation/creationMng_(20).png'),
                    iconActive: require('@/assets/images/creation/creationMng_(19).png'),
                    label: '设置',
                    value: 3,
                },
            ],
            contentHearderList: [
                {
                    label: '样式',
                    value: 1,
                },
                {
                    label: '图层管理',
                    value: 2,
                },
            ],
            tabActive: 1,
            tabName: '我的',
            contentHearder: 1,
        }
    },
    methods: {
        tabChange(item) {
            this.tabActive = item.value
            this.tabName = item.label
        },
        contentChange(val) {
            this.contentHearder = val
        },
    }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>