<template>
	<div class='video_works'>
		<creation-stat :statInfo="statInfo"></creation-stat>
		<div class="mt20 sec_wrap p20">
			<div class="tab">
				<div class="tabs_item" :class="is_draft == item.value ? 'active' : ''" v-for="(item, index) in tabs"
					:key="index" @click="tabChange(item)">
					<div class="txt">{{ item.label }}</div>
					<div class="bar"></div>
				</div>
			</div>
			<div class="list_con p20">
				<div class="search_box">
					<div class="flex_box">
						<div class="form_item" v-if="is_draft == 2">
							<div class="label">状态</div>
							<el-select class="form_val" size="small" v-model="params.status" placeholder="请选择"
								@change="search">
								<el-option v-for="item in status_list" :key="item.value" :label="item.label"
									:value="item.value" :clearable="true">
								</el-option>
							</el-select>
						</div>
						<div class="form_item ml10">
							<div class="label">作品分类</div>
							<el-select class="form_val" size="small" v-model="params.video_category" placeholder="请选择作品分类"
								@change="search">
								<el-option v-for="item in cate_list" :key="item.value" :label="item.label"
									:value="item.value" :clearable="true">
								</el-option>
							</el-select>
						</div>
						<div class="form_item">
							<el-input class="form_val" size="small" placeholder="搜索作品名称" suffix-icon="el-icon-search"
								v-model="params.name" @keyup.enter.native="search" :clearable="true">
							</el-input>
						</div>
					</div>
					<div class="form_item">
						<div class="label">筛选</div>
						<el-select class="form_val" size="small" v-model="params.order_field" placeholder="请选择"
							@change="search">
							<el-option v-for="item in time_list" :key="item.value" :label="item.label" :value="item.value"
								:clearable="true">
							</el-option>
						</el-select>
					</div>
				</div>
				<video-list v-if="list.length" :list="list" @refreshBtn="getList" />
				<empty-data v-else v-loading="loading" img='no_img_b.png' text='这里什么都没有哦~' :is_video="true"></empty-data>
			</div>
			<div class="page_box mt20">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
	</div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页

import CreationStat from '../components/creationStat/index'
import VideoList from '../components/creationList/video'
export default {
	components: { EmptyData, PagingPage, CreationStat, VideoList },
	props: {},
	data() {
		return {
			statInfo: {},
			tabs: [
				{
					label: '我的成片',
					value: 2,
				},
				{
					label: '我的草稿',
					value: 1,
				},
			],
			is_draft: 2,
			cate_list: [],
			params: {},
			page: 1,
			limit: 10,
			total: 0,
			time_list: [],
			status_list: [],
			list: [],
			loading: false,
		}
	},
	computed: {},
	watch: {},
	created() {
        this.getStat()
		this.getParams()
		this.getList()
	},
	mounted() { },
	methods: {
		tabChange(item) {
			this.is_draft = item.value
			this.resetFrom()
		},
		search() {
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
			this.params.video_category = ''
			this.params.order_field = ''
			this.params.status = ''
			this.params.name = ''
			this.search()
		},
		getList() {
			const data = {
				page: this.page,
				limit: this.limit,
				is_draft: this.is_draft,
				...this.params,
			}
			this.loading = true
			this.$creationApi.getVideoList(data).then(res => {
				this.loading = false
				if (res.code == 1000) {
					this.list = res.result.data
					this.total = res.result.total

				} else {
					this.$errMsg(res.message)
				}
			})

		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		getParams() {
			this.$creationApi.getVideoParams().then(res => {
				if (res.code == 1000) {
					this.cate_list = res.result.category
					this.status_list = res.result.status
					this.time_list = res.result.order_field
				} else {
					this.$errMsg(res.message)
				}
			})
		},
		getStat() {
            this.$creationApi.getVideoStat().then(res => {
				if (res.code == 1000) {
                    this.statInfo = res.result
				} else {
					this.$errMsg(res.message)
				}
			})
        }
	},
}
</script>

<style lang='scss' scoped>
@import '../creation.scss';

.video_works {
	width: 100%;
}
</style>