<template>
    <div class="con_top">
        <div class="con_top_l sec_wrap p20">
            <div class="con_top_l_l">
                <div class="con_info">
                    <img src="@/assets/images/creation/creationMng_(33).png" alt="">
                    <div class="con_info_r">
                        <div class="label">未完成</div>
                        <div class="value">{{ statInfo.incomplete || 0 }}</div>
                    </div>
                </div>
            </div>
            <div class="con_top_l_r">
                <div class="item_info">
                    <div class="label">
                        <div class="circle"></div>
                        创作中
                    </div>
                    <div class="value">{{ statInfo.creation || 0 }}</div>
                </div>
                <div class="item_info">
                    <div class="label">
                        <div class="circle"></div>
                        合成中
                    </div>
                    <div class="value">{{ statInfo.synthesis || 0 }}</div>
                </div>
                <div class="item_info">
                    <div class="label">
                        <div class="circle"></div>
                        排队中
                    </div>
                    <div class="value">{{ statInfo.in_line || 0 }}</div>
                </div>
                <div class="item_info">
                    <div class="label">
                        <div class="circle"></div>
                        创作失败
                    </div>
                    <div class="value">{{ statInfo.failed || 0 }}</div>
                </div>
            </div>
        </div>
        <div class="con_top_r p20">
            <div class="con_info">
                <img src="@/assets/images/creation/creationMng_(34).png" alt="">
                <div class="con_info_r">
                    <div class="label">已完成</div>
                    <div class="value">{{ statInfo.completed || 0 }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        statInfo: {
            type: Object,
            default: () => {}
        }

    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
.con_top {
    display: flex;

    .con_top_l {
        width: 70%;
        display: flex;

        .con_top_l_l {
            width: 30%;
            border-right: 2px solid #FFFFFF;
        }

        .con_top_l_r {
            width: 70%;
            display: flex;
            align-items: center;

            .item_info {
                width: 25%;
                margin-left: 40px;

                .label {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;

                    .circle {
                        width: 8px;
                        height: 8px;
                        background: $blueColor1;
                        border-radius: 100%;
                        margin-right: 5px;

                    }

                }

                .value {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }

                &:last-child {
                    .circle {
                        background-color: #F22E2E;
                    }
                }
            }
        }
    }

    .con_top_r {
        width: 30%;
        margin-left: 20px;
        border-radius: 10px;
        background: url('~@/assets/images/creation/creationMng_(30).png');
        background-size: cover;

        .label {
            color: #fff !important;
        }

        .value {
            color: #fff !important;
        }
    }

    .con_info {
        display: flex;
        align-items: center;

        img {
            width: 66px;
            height: 66px;
            // background: linear-gradient(180deg, #C5D8FF 0%, #6FA2FF 100%);
            margin-right: 30px;
        }

        .con_info_r {
            .label {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }

            .value {
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
            }
        }

    }
}
.sec_wrap {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;

}
.p20 {
    padding: 20px;
}

.p30 {
    padding: 30px;
}
.ml10{
    margin-left: 10px;
}

.flex_box{
    display: flex;
}
</style>